#merch {
  color: antiquewhite;
  padding: 20px 0;
  text-align: center;
}

#merch h2 {
  text-align: center;
  font-size: 6vh;
}

.products {
  display: flex; /* Use flexbox */
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically if they have height */
  flex-wrap: wrap; /* Wrap the items if they overflow */
  gap: 20px; /* Add space between items */
}

.product {
  border: 1px solid #ddd;
  padding: 20px 10px;
  border-radius: 5px;
  text-align: center;
  width: 200px;
  height: 400px;
}

.product img {
  max-width: 100%;
  height: auto;
}

.product h3 {
  align-content: center;
  margin: 10px 0;
  height: 100px;
}

.product p {
  margin: 5px 0;
}

.product a {
  display: inline-block;
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #46b3e6;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.product a:hover {
  background-color: #3590c4;
}

#paypal-form-fields-container-F9PPNQZS48H34 {
  margin-bottom: 1px !important;
}

#paypal-container-F9PPNQZS48H34 {
  width: 300px;
  height: auto;
  margin: 3vh auto -2vh auto; 
  padding: 20px; 
  border: 1px solid #ddd; 
  border-radius: 5px; 
  background-color: #1c1a1a; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
  text-align: center;
}