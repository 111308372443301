.App {
  display: flex;
  flex-direction: row; /* Horizontal layout */
  align-items: stretch; /* Stretch children full height */
  width: 100%;
  min-height: 100vh;
}


@font-face {
  font-family: 'Got_Heroin';
  src: url('./Assets/Got_Heroin.ttf') format('truetype');
}

@font-face {
  font-family: 'Adler';
  src: url('./Assets/Adler.ttf') format('truetype');
}

@font-face {
  font-family: 'xfiles';
  src: url('./Assets/xfiles.ttf') format('truetype');
}

.special-font {
  font-family: Arial, sans-serif;
}


#load {
  margin: 0px;
  padding: 0px;
  display: none;
}


html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  background-color: black;
  font-family: 'Adler', sans-serif;
}

body, html {
  height: 100%; 
  margin: 0; /* Remove default margin */
}

.row, .col {
  padding: 0;
}

.container {
  margin: 0;
}

.main-content {
  box-sizing: border-box;
  min-height: 100vh;
  /* width: 100vw; */
  display: flex;
  flex-direction: column;
}

.content {
  padding-left: 150px; /* Space for the sidebar */
  width: 100%; /* Full width of the parent container */
  min-height: 100vh;
}

#home {
  width: 100%;
  min-height: 60vh;
}

#tagline h1{
  text-align: center;
  font-family: 'xfiles', sans-serif;
  color:antiquewhite;
  font-size: 3.4vw;
  letter-spacing: .07em;
  padding-top: 15px;
}

#banner {
  width: 100%;
  overflow: hidden;
}

#about, #contact {
  color:antiquewhite;
  margin: 0 10vw;
  max-width: 100vw;
  min-height: 10vh;
  
}

#about {
  padding: 7vh 11vw;
  letter-spacing: .09em;
}

#about h5 {
  color:aliceblue;
  font-family: 'Courier New', Courier, monospace;
  font-style: oblique;
}

#events h2 {
  display: grid;
  width: 100%;
  justify-items: center !important;
  font-size: 5vw;
  color:antiquewhite;
  margin-top: 3vh;
  padding-top: 30px;
  background-color: #282828;
}

#eventImages {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(150px, auto);
  gap: 20px;
  justify-items: center; 
  align-items: center; 
  margin: 0;
  padding: 0 10vw;
}

#events img {
  width: 32vw;
  margin-top: 10px;
  padding: 1vh 0 3vh 0;
  box-sizing: border-box;
}

.upcoming {
  justify-self: center;
}

#eventImages.upcoming.single {
  grid-template-columns: 1fr; 
  justify-items: center; 
  margin: 0;
  width: 100% !important;
  height: auto !important;
}

#stayTuned {
  width: 50vw !important;
}

.react-calendar {
  font-family: 'xfiles', sans-serif;
  width: auto;
  max-width: 80%; 
  background-color: #fff;
  border: 1px solid #a0a096;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  margin: 20px 10%;
}

.react-calendar__tile--active p {
  background-color: #46b3e6;
  color: white;
}

.event-date {
  color: #ffeb3b !important; 
  background-color: black !important;
  font-weight: bold;
}

.event-date:hover {
  background-color: #2c2b27 !important; /* Hover color for event dates */
}


#modal {
  max-width: 50%;
  height: auto;
}

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.custom-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #282828;
  padding: 20px;
  border-radius: 8px;
  outline: none;
  width: 80%;
  max-width: 500px;
  color: white;
}

#merch h2 {
  font-size: 4vw;
  padding-top: 25px;
  background-color: #2b2b298f;
}



@media (max-width: 991px) {

  .content {
    padding-left: 0;
    width: 100vw; 
    min-height: 100vh;
  }

  .main-content {
    box-sizing: border-box;
    width: 100vw;
    min-height: 100vh; 
    display: flex;
    flex-direction: column;
  }

  #home, #banner, #tagline h1, #gallery, #about, #events h2, #contact, .col, #merch {
    padding: 0;
    margin: 1vh 0;
    width: 100%;
    min-height: 0;
  }

  #about h2, #events h2 {
    margin-left: 10px;
    font-size: 7vw;
  }

  #events h2 {
    display: grid;
    justify-items: center !important;
    align-items: center !important;
    width: 100%;
    padding-top: 2vh;
    margin-left: 0;
    margin-top: 5vh;
    background-color: #2b2b298f;
  }

  #merch h2 {
    font-size: 7vw;
    padding-top: 25px;
    background-color: #2b2b298f;
  }

  #banner {
    width: 100%; /* Adjust width considering sidebar */
    margin-left: 0;
  }

  #tagline h1 {
    margin-left: 1%;
    font-size: 8vw;
  }

  #about h5 {
    margin: .9vh 10vw;
    font-size: 3vw;
  }

  #events {
    gap: 5%;
    padding-bottom: 15%;
  }

  #eventImages {
    display: grid;
    grid-template-columns: repeat(2, 1fr);  
  }
  
  #eventImages img {
    width: 100%; 
    height: auto; 
    padding: 1vh 0 1vw 0;
    box-sizing: border-box;
  }
  
  .upcoming {
    grid-column: span 2; 
    justify-self: center;
  }
  
  #eventImages.upcoming.single {
    grid-template-columns: 1fr; 
    justify-items: center; 
    margin: 0;
    padding: 0;
    width: 100% !important;
    height: auto !important;
   
  }
  
  #stayTuned {
    width: 90vw !important;
    margin: 0;
    padding: 0;
  }
}