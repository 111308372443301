#gallery {
    width: 100%;
    min-height: 20vh
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  
}

.gallery-container img {
  width: 100vw;
  border-radius: 4px;
}

#gallery .carousel-item {
  height: auto; /* Adjust this height as needed */
}

#gallery .gallery-image {
  width: 100%;
  height: 100%;
  object-fit: contain; /* This will cover the area, cropping the image if necessary */
}

@media (max-width: 991px) {
  #gallery {
    width: 100vw;
    min-height: 2vh;
    padding-left: 0;
}
}
  
  