.video-section {
    padding: 40px 0; /* Adjust padding as needed */
    display: flex;
    justify-content: center;
  }
  
  .youtube-video {
    width: 560px; /* Default width */
    height: 315px; /* Default height, based on a 16:9 aspect ratio */
    max-width: 100%; /* Ensure it doesn't exceed the container's width */
  }
  
  /* Responsive adjustments */
  @media (max-width: 991px) {
    .youtube-video {
      max-width: 500px;
      height: 300px;
    }
  }
  