
.navbar {
  background-color: #333;
}

@media (min-width: 992px) {
  .navbar {
    flex-direction: column; /* Stack the navbar items vertically */
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 150px; /* Adjust the sidebar width */
    height: 100vh;
  }

  .navbar-collapse {
    display: flex; /* Enable flexbox for the collapse container */
    flex-direction: column; /* Stack items vertically in the collapse container */
  }

  #SideLogo {
    max-width: 125px;
  }

  .navbar-nav {
    flex-direction: column; /* Ensure nav items are stacked vertically */
    padding: 130px 0;
  }

  a.nav-link {
    color: white;
    padding: 27px;
    text-align: center;
  }

  .me-auto {
    margin-right: 0px;
  }

  .navbar-toggler {
    display: none; /* Hide toggler on larger screens */
  }
}

/* Styles for smaller screens */
@media (max-width: 991px) {
  .navbar {
    width: 100%;
  }

  #SideLogo {
    max-width: 100px;
    /* margin: 157 0 0 0 px; */
  }

  a.nav-link {
    color: white;
    padding: 27px;
  }

  .nav-link {
    color: antiquewhite;
  }
}
