#footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  text-align: center;
  background: linear-gradient(to top, #1f2124, #000000);
  color: antiquewhite;
  width: 100%;
  padding: 20px 0;
  margin-top: auto;
  gap: 20px;
  font-family: 'American Typewriter'
}

.instagram-icon {
  color: #E1306C; /* Instagram pink color */
}

.youtube-icon {
  color: #FF0000; /* YouTube red color */
}

.footer-column {
  margin-left: 5vw;
}

.footer-logo img {
  grid-column: 2; /* Center the logo in the second column */
  max-width: 100px; /* Adjust the size of the logo as needed */
  height: auto;
}

.email {
  color: white;
}

.social-media, .contact-info, .copyright {
  grid-column: 3; /* Position the text in the third column */
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 19vw;
}

.social-media a, .contact-info p, .copyright p {
  margin-bottom: 10px; 
  font-size: 0.9rem;
}

.contact-info p, .copyright p {
  margin-right: 2vw;
}

/* Responsive adjustments */
@media (max-width: 991px) {
  #footer {
    grid-template-columns:auto;
    min-width: 100%;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  
  #footer-column {
    margin-left: 0;
    width: 100%;
  }

  .social-media, .contact-info, .copyright {
    grid-column: 1; /* Stack the text in the single column */
    align-items: center;
    min-width: 0vw;
  }

  #footer a, .contact-info p, .copyright p {
    font-size: 1rem; /* Adjust font size for readability on small screens */
  }

  .contact-info p, .copyright p {
    margin-right: 0;
  }
}